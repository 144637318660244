import React from 'react';
import Layout from '../components/organisms/Layout';
import SEO from '../components/molecules/SEO';
import Image from '../components/elements/Image';

const IndexPage = () => (
  <Layout hideFooter invertHeader>
    <SEO title="Home" keywords={[ `gatsby`, `application`, `react` ]}/>
      <Image
        style={{
          position: 'fixed',
          zIndex: -1,
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
        imgName="chalkhorse-scaf-1.jpg"/>
  </Layout>
);

export default IndexPage;
